import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Alert,
  Snackbar,
} from "@mui/material";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import style from "./Application.module.scss";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const swedishNumberRegexp = `^(([+]46)\\s*(7)|07)[02369]\\s*(\\d{4})\\s*(\\d{3})$`;

const validationSchema = yup.object({
  firstName: yup
    .string("Skriv in förnamn")
    .matches(/^[A-Öa-ö ]*$/, "Ange bokstäver A-Ö")
    .max(30, "Ange färre tecken")
    .required("Obligatoriskt fält"),
  lastName: yup
    .string("Skriv in förnamn")
    .matches(/^[A-Öa-ö ]*$/, "Ange bokstäver A-Ö")
    .max(30, "Ange färre tecken")
    .required("Obligatoriskt fält"),
  email: yup
    .string("Ange e-post")
    .max(70, "Ange färre tecken")
    .email("Ange en giltig e-post adress")
    .required("Obligatoriskt fält"),
  phoneNumber: yup
    .string("Ange mobilnummer")
    .matches(
      swedishNumberRegexp,
      "Ogiltigt mobilnummer - ange enbart siffror t.ex. 070 1234 567"
    )
    .max(15, "Ogiltigt antal tecken"),
});

const Application = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const fileRef = useRef();
  const [pickedFile, setPickedFile] = useState("");
  const [fileError, setFileError] = useState(false);
  const [fileErrorText, setFileErrorText] = useState("");

  const submitForm = (values, resetForm) => {
    console.log("values: ", values);
    console.log("skickar in formulär");

    const formData = new FormData();
    formData.append("file", pickedFile);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("phoneNumber", values.phoneNumber);

    setSubmittingForm(true);

    fetch("mailScript.php", {
      method: "POST",
      body: formData,
    })
      .then((r) => {
        r.ok && setShowSuccess(true);
        fileRef.current.value = "";
        resetForm();
        setSubmittingForm(false);
      })
      .catch(() => {
        fileRef.current.value = "";
        resetForm();
        setSubmittingForm(false);
      });
  };

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) =>
      !fileError && submitForm(values, resetForm),
  });

  const { handleSubmit, handleChange, errors, touched, values } = formik;

  const handleFileChange = (event) => {
    if (event.target?.files.length > 0) {
      const file = event.target?.files[0];
      setPickedFile(file);
      if (validFileSize(file.size) && validFileType(file.type)) {
        setFileError(false);
        setFileErrorText("");
      }
    } else {
      setFileError(true);
      setFileErrorText("Obligatoriskt fält");
    }
  };

  const validFileSize = (fileSize) => {
    if (fileSize > 20000000) {
      setFileError(true);
      setFileErrorText("Filstorlek för stor (max 20mb)");
      return false;
    }
    return true;
  };

  const validFileType = (fileType) => {
    if (fileType === "application/pdf") {
      return true;
    } else {
      setFileError(true);
      setFileErrorText("Fel filformat, välj pdf.");
      return false;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccess(false);
  };

  const alertSnack = () => {
    const successMessage = "Tack för din ansökan, vi återkommer inom kort!";
    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <div>
      <div className={style.descriptionContainer}>
        <div className={style.headerText}>
          Ansök nu och bli en del av Kraftbyrån!
        </div>
        <div className={style.recruitmentText}>
          Vi rekryterar ständigt och är öppna för alla som vill utvecklas inom
          sin yrkesroll eller vill utveckla nya kunskaper och pröva på vad vi
          har att erbjuda. Är du ute efter att vidga dina kunskaper samt vidga
          våra teknik områden? Var med och led dina drömprojekt och vässa din
          kompetens på vägen!
        </div>
        <div className={style.experienceContainer}>
          <div style={{ fontWeight: "bold" }}>
            Vi ser gärna att du har erfarenhet, kunskap eller relevanta meriter:
          </div>
          <ul>
            <li>Har dokumenterad erfarenhet inom elkraftsteknik</li>
            <li>
              God datorvana, CAD program (AutoCAD, progeCAD eller liknande)
            </li>
            <li>B-körkort</li>
            <li>
              Utöver de praktiska erfarenheterna så ser vi gärna att du kan ta
              initiativ, är social samt ansvarstagande
            </li>
          </ul>
        </div>
      </div>

      <form onSubmit={handleSubmit} className={style.form}>
        <div className={style.fomInputGroup}>
          <div className={style.formInputContainer}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="firstName">Förnamn</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                label="Förnamn"
                value={values.firstName}
                onChange={handleChange}
                error={touched.firstName && Boolean(errors.firstName)}
              />
              <FormHelperText id="firstName">
                {touched.firstName && errors.firstName}
              </FormHelperText>
            </FormControl>
          </div>
          <div className={style.formInputContainer}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="lastName">Efternamn</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                label="Efternamn"
                value={values.lastName}
                onChange={handleChange}
                error={touched.lastName && Boolean(errors.lastName)}
              />
              <FormHelperText id="lastName">
                {touched.lastName && errors.lastName}
              </FormHelperText>
            </FormControl>
          </div>
        </div>

        <div className={style.fomInputGroup}>
          <div className={style.formInputContainer}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="email">E-post</InputLabel>
              <Input
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
              />
              <FormHelperText id="email">
                {touched.email && errors.email}
              </FormHelperText>
            </FormControl>
          </div>
          <div className={style.formInputContainer}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="phoneNumber">Mobilnummer</InputLabel>
              <Input
                id="phoneNumber"
                name="phoneNumber"
                label="Mobilnummer"
                value={values.phoneNumber}
                onChange={handleChange}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              />
              <FormHelperText id="phoneNumber">
                {touched.phoneNumber && errors.phoneNumber}
              </FormHelperText>
            </FormControl>
          </div>
        </div>

        <div style={{ margin: 10, marginTop: 20, width: '100%' }}>
          <InputLabel htmlFor="file">Bifoga ditt CV (pdf-format)</InputLabel>
          <FormControl variant="standard" fullWidth>
            <Input
              inputRef={fileRef}
              id="file"
              name="file"
              type="file"
              inputProps={{ accept: ".pdf" }}
              onChange={handleFileChange}
              error={fileError}
            />
            <FormHelperText id="fileUpload">
              {fileError && fileErrorText}
            </FormHelperText>
          </FormControl>
        </div>

        <div
          style={{ display: "flex", justifyContent: "center", padding: "30px" }}
        >
          <LoadingButton
            loading={submittingForm}
            variant="contained"
            type="submit"
            style={{ textTransform: "none" }}
            size="large"
          >
            Skicka ansökan
          </LoadingButton>
        </div>
      </form>
      {alertSnack()}
    </div>
  );
};

export default Application;
