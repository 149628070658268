import windTurbine from "../../assets/images/wind_turbines.jpg";
import solutions from "../../assets/images/solutions.jpg";
import style from "./Home.module.scss";
import powerPlant from "../../assets/icons/power_plant.png";
import tunnel from "../../assets/icons/tunnel.png";
import ecoHouse from "../../assets/icons/eco_house.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div>

      <div className={style.captionContainer}>
        <div className={style.caption}>
          <h1>Kraftbyrån AB</h1>
          <p>
            Vi erbjuder lösningar inom teknik, konstruktion och underhåll till våra kunder.
            Med kapacitet att leverera komplexa projekt såväl som nyckelfärdiga lösningar i energisektorn finns vi där
            för våra kunder längs hela leveranskedjan.
          </p>
        </div>
      </div>

      {/*<div className={style.segment}>
        <div className={style.textSection}>
          <div className={style.textContainer}>
            <h2>Totalentreprenad, våra kunder och våra samarbetspartners</h2>
            <p className={style.segmentText}>
              Våra kunder finns över hela Stockholmsområdet med kärnan i norrort. Fastighetsbolag, BRF:er,
              byggentreprenörer, andra hantverkargrupper och självklart privatpersoner.

              Vi kan genom vårt täta samarbete med andra yrkesgrupper och vår långa kunskap erbjuda totalentreprenad.
              Kontakta oss gärna så berättar vi mer.
            </p>
          </div>
        </div>
        <img src={windTurbine} className={style.image}/>
      </div>

      <div className={style.segment}>
        <img src={solutions} className={style.image}/>
        <div className={style.textSection}>
          <div className={style.textContainer}>
            <h2>Vi skapar helhetslösningar</h2>
            <p className={style.segmentText}>
              Våra konsulters breda kompetens och expertkunskap gör oss till en av marknadens starkaste leverantörer av
              helhetslösningar för kostnadseffektiva och hållbara elnät.
              Medarbetare och partners har tillsammans en gedigen kunskap och erfarenhet inom elkraft, inmätning, GIS,
              tillstånd, miljö, fiber mm.
              Vi arbetar aktivt med rekrytering och kompetensutveckling för att ständigt förstärka oss.
            </p>
          </div>
        </div>
      </div>

      <div className={style.serviceContainer}>
        <div className={style.buttonContainer}>
          <h2>Vi har gedigen erfarenhet inom flera arbetsområden</h2>
          <Button variant="contained" onClick={() => navigate("/tjanster")}>Läs mer</Button>
        </div>

        <div className={style.container}>
          <div className={style.iconContentContainer}>
            <img src={powerPlant} className={style.icon}/>
            <h2 className={style.subHead}>Elnät</h2>
          </div>

          <div className={style.iconContentContainer}>
            <img src={tunnel} className={style.icon}/>
            <h2 className={style.subHead}>GIS & mätning</h2>
          </div>

          <div className={style.iconContentContainer}>
            <img src={ecoHouse} className={style.icon}/>
            <h2 className={style.subHead}>Fiber</h2>
          </div>
        </div>
      </div>*/}

    </div>
  );
};

export default Home;