import style from "./Expertise.module.scss"
import powerPlant from "../../assets/icons/power_plant.png"
import ecoHouse from "../../assets/icons/eco_house.png"
import tunnel from "../../assets/icons/tunnel.png"
import cityScape from "../../assets/images/cityScape.png"
import gis from "../../assets/images/gis.jpg"
import electricity_net from "../../assets/images/electricity_net.jpg"
import fiber from "../../assets/images/fiber.jpg"
import UnderConstructionIcon from "../../assets/icons/UnderConstructionIcon";

const Expertise = () => (
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>

    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <div style={{ fontWeight: 600, fontSize: "28px" }}>Hemsidan är under uppbyggnad, mer innehåll kommer snart!</div>
      <UnderConstructionIcon />
    </div>

    {/*<div className={style.card}>
      <h1 className={style.header}>
        Våra tjänster
      </h1>

      <div className={style.divider} />

      <div className={style.container}>
        <div className={style.iconContentContainer}>
          <img src={powerPlant} className={style.icon} />
          <h2 className={style.subHead}>Elnät</h2>
          <div className={style.text}>Hållbara lösningar inom elnät </div>
        </div>

        <div className={style.iconContentContainer}>
          <img src={tunnel} className={style.icon} />
          <h2 className={style.subHead}>GIS & mätning</h2>
          <div className={style.text}>Vi levererar tjänster inom GIS och mätning</div>
        </div>

        <div className={style.iconContentContainer}>
          <img src={ecoHouse} className={style.icon} />
          <h2 className={style.subHead}>Fiber</h2>
          <div className={style.text}>Innovation och teknik för framtidens fiber</div>
        </div>
      </div>

      <div className={style.segmentDivider}>
        <img src={cityScape}/>
      </div>


      <div className={style.serviceWrapper}>
        <div className={style.detailContainer}>
          <div>
            <h1>Elnät</h1>
            <div className={style.description}>
              Kraftbyrån besitter gedigen kompetens inom lokalnät.
              Våra elkraftsingenjörers kunskap och erfarenhet gör att vi kan möta våra kunders behov och leverera kompletta och smarta lösningar för kraftledningar i mark, vatten och luft.
              Teknik och miljö samverkar för ett hållbart samhälle.
            </div>
            <ul>
              <li>Nätplanering</li>
              <li>Dokumentation</li>
              <li>Projektering</li>
              <li>Besiktning</li>
            </ul>
          </div>
          <img src={electricity_net} className={style.image}/>
        </div>

        <div className={style.greySegment}>
          <div className={style.detailContainer}>
            <img src={gis} className={style.image}/>
            <div>
              <h1>GIS & mätning</h1>
              <div className={style.description}>
                Vi levererar tjänster inom GIS och mät till våra kunder, främst inom bearbetning och hantering av geo-data, kartframställning och analyser samt mätning i våra projekt.
                Vår akademiska grund inom lantmäteri och GIS tillsammans med mångårig erfarenhet inom elkraftsbranschen och fältarbete gör oss till en given och pålitlig leverantör.
              </div>
              <ul>
                <li>GIS-samordning</li>
                <li>Kartproduktion</li>
                <li>Mätning</li>
                <li>Kabelsökning</li>
              </ul>
            </div>
          </div>
        </div>


        <div className={style.detailContainer}>
          <div>
            <h1>Fiber</h1>
            <div className={style.description}>
              För en hållbar framtid arbetar vi med robust fiber och aktuell fiberteknologi, vilket ger driftsäkra och kostnadseffektiva anläggningar.
            </div>
            <ul>
              <li>Nätanalyser</li>
              <li>Närplanering</li>
              <li>Beredning</li>
              <li>Projektledning</li>
              <li>Byggledning</li>
            </ul>
          </div>
          <img src={fiber} className={style.image}/>
        </div>
      </div>

    </div>*/}
  </div>
);

export default Expertise;