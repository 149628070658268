import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/Home/Home";
import About from "./routes/About/About";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import style from "./App.module.scss";
import Expertise from "./routes/Expertise/Expertise";
import Contact from "./routes/Contact/Contact";
import ScrollToTop from "./components/ScrollToTop";
import Application from "./routes/Application/Application";

function App() {
  return (
    <BrowserRouter>
      <div className={style.container}>
        <Header />
        <div className={style.content}>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/om-oss" element={<About />} />
              <Route path="/tjanster" element={<Expertise />} />
              <Route path="/kontakta-oss" element={<Contact />} />
              <Route path="/ansokan" element={<Application />} />
            </Routes>
          </ScrollToTop>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
