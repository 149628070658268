import style from "./Footer.module.scss";
import logo from "../../assets/images/kraftbyrån_logga_uncropped_small.png";
import MailIcon from '@mui/icons-material/Mail';
import CopyrightIcon from '@mui/icons-material/Copyright';

const Footer = () => (
  <div className={style.container}>
    <div className={style.wrapper}>
      <div className={style.content}>
        <h3>Kraftbyrån AB</h3>

        <div className={style.textIconContainer}>
          <CopyrightIcon color="warning" />
          <div className={style.text}>Företaget innehar F-skatt</div>
        </div>
      </div>
      <div className={style.content}>
        <h3>Kontakta oss</h3>
        <div className={style.textIconContainer}>
          <MailIcon color="warning"/>
          <a className={style.text} href="mailto:info@kraftbyran.org">info@kraftbyran.org</a>
        </div>
      </div>
      <div className={style.imageContainer}>
        <img src={logo}/>
      </div>
    </div>
  </div>
);

export default Footer;