import style from "./About.module.scss"
import care from "../../assets/icons/care.png";
import battery from "../../assets/icons/eco-battery.png";
import projectManagement from "../../assets/icons/project-management.png";

const About = () => (
  <div>
    <div className={style.image}/>
    <div className={style.contentContainer}>
      <h1>Om oss</h1>
      <div className={style.text}>
        <b>Kraftbyrån</b> är konsultbolaget som levererar helhetslösningar inom elkraft. Med vår samlade kompetens skapar vi smarta och hållbara lösningar för framtidens elnät.
        Vi på Kraftbyrån sätter stort värde på både utveckling och välmående av våra medarbetare.
        Vår starka värdegrund tillsammans med våra medarbetares driv och optimism gör oss till en attraktiv arbetsgivare med hög medarbetarnöjdhet. Detta resulterar i god kvalitet och goda kundrelationer.
      </div>
    </div>

    <div>

    </div>
    <h2 className={style.header}>Vi strävar efter miljövänliga och innovativa arbetssätt</h2>
    <div className={style.container}>
      <div className={style.iconContentContainer}>
        <img src={care} className={style.icon}/>
        <div className={style.subHead}>Energi med låga koldioxidutsläpp</div>
        <div className={style.text}>Lösningar för energikällor med låga koldioxidutsläpp och utveckling av elnäten</div>
      </div>
      <div className={style.iconContentContainer}>
        <img src={battery} className={style.icon}/>
        <div className={style.subHead}>Optimerad energianvändning</div>
        <div className={style.text}>Vi främjar optimerad energiförbrukning</div>
      </div>
      <div className={style.iconContentContainer}>
        <img src={projectManagement} className={style.icon}/>
        <div className={style.subHead}>Innovativa samarbeten och lösningar</div>
        <div className={style.text}>Implementerar hållbara innovationer</div>
      </div>
    </div>
  </div>
);

export default About;