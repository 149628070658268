import { useState } from "react";
import { NavLink } from "react-router-dom";
import style from "./Header.module.scss";
import croppedLogo from "../../assets/icons/kraftbyrån_logo_cropped.png";
import croppedLogo_small from "../../assets/icons/kraftbyrån_logo_cropped_small.png";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import WorkIcon from "@mui/icons-material/Work";
import InfoIcon from "@mui/icons-material/Info";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { PersonAdd } from "@mui/icons-material";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className={style.container}>
      <div>
        <NavLink
          to={"/"}
          className={({ isActive }) =>
            isActive ? style.activeLink : style.link
          }
        >
          <img src={croppedLogo} className={style.croppedLogo} />
        </NavLink>
      </div>
      <div className={style.linkContainer}>
        <NavLink
          to={"/tjanster"}
          className={({ isActive }) =>
            isActive ? style.activeLink : style.link
          }
        >
          TJÄNSTER
        </NavLink>
        <NavLink
          to={"/ansokan"}
          className={({ isActive }) =>
            isActive ? style.activeLink : style.link
          }
        >
          VI REKRYTERAR
        </NavLink>
        <NavLink
          to={"/om-oss"}
          className={({ isActive }) =>
            isActive ? style.activeLink : style.link
          }
        >
          OM OSS
        </NavLink>
        <NavLink
          to={"/kontakta-oss"}
          className={({ isActive }) =>
            isActive ? style.activeLink : style.link
          }
        >
          KONTAKT
        </NavLink>
      </div>
      <div className={style.menuContainer}>
        <IconButton onClick={() => setOpenMenu(!openMenu)}>
          {openMenu ? (
            <MenuOpenIcon fontSize="large" />
          ) : (
            <MenuIcon fontSize="large" />
          )}
        </IconButton>
        <div className={style.drawerContainer}>
          <Drawer
            anchor="left"
            open={openMenu}
            onClose={() => setOpenMenu(false)}
          >
            <div
              className={style.iconAndLink}
              onClick={() => setOpenMenu(false)}
            >
              <NavLink
                to={"/"}
                className={({ isActive }) =>
                  isActive ? style.activeLink : style.link
                }
              >
                <img
                  src={croppedLogo_small}
                  className={style.croppedLogoSmall}
                />
              </NavLink>
              <IconButton onClick={() => setOpenMenu(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <div
              className={style.iconAndLink}
              onClick={() => setOpenMenu(false)}
            >
              <WorkIcon />
              <NavLink
                to={"/tjanster"}
                className={({ isActive }) =>
                  isActive ? style.activeLink : style.link
                }
              >
                TJÄNSTER
              </NavLink>
            </div>
            <div
              className={style.iconAndLink}
              onClick={() => setOpenMenu(false)}
            >
              <PersonAdd />
              <NavLink
                to={"/ansokan"}
                className={({ isActive }) =>
                  isActive ? style.activeLink : style.link
                }
              >
                VI REKRYTERAR
              </NavLink>
            </div>
            <div
              className={style.iconAndLink}
              onClick={() => setOpenMenu(false)}
            >
              <InfoIcon />
              <NavLink
                to={"/om-oss"}
                className={({ isActive }) =>
                  isActive ? style.activeLink : style.link
                }
              >
                OM OSS
              </NavLink>
            </div>
            <div
              className={style.iconAndLink}
              onClick={() => setOpenMenu(false)}
            >
              <ContactMailIcon />
              <NavLink
                to={"/kontakta-oss"}
                className={({ isActive }) =>
                  isActive ? style.activeLink : style.link
                }
              >
                KONTAKT
              </NavLink>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};
export default Header;
