import style from "./Contact.module.scss"
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import sweden_map from "../../assets/images/sweden_map.png"

const Contact = () => (
  <div className={style.container}>
    <div className={style.wrapper}>
      <h2>KONTAKTA OSS</h2>

      <div className={style.contentContainer}>

        <div>
          <List className={style.list}>
            <div className={style.itemContainer}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PhoneIphoneIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Farid Friezadeh" secondary="070 - 996 63 30" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PhoneIphoneIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Balen Delshad" secondary="072 - 224 53 72" />
              </ListItem>

            </div>
            <Divider/>

            <div className={style.itemContainer}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <MailIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="info@kraftbyran.se" />
              </ListItem>
            </div>
          </List>

        </div>

        <div className={style.mapContainer}>
          <div className={style.text}>
            <b>Kraftbyrån</b> har bas i Stockholm men är öppna för såväl samarbeten med nya kunder samt framtida medarbetare oavsett geografiskt läge i landet.
          </div>
          <img src={sweden_map} className={style.image} />
        </div>

      </div>

    </div>
  </div>
);

export default Contact;